.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
}

.container {
    display: flex;
    flex-flow: row nowrap;
    gap: 24px;
}

.link {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    span {
        margin-left: 20px;
        margin-right: 8px;
        font-size: 16px;
        color: #edeff4;
        font-weight: 400;
    }

    &:hover {
        svg {
            &:first-child {
                path {
                    fill: #89a5eb;
                }
            }

            &:last-child {
                path {
                    stroke: #89a5eb;
                }
            }
        }

        span {
            color: #89a5eb;
        }
    }
}

@media screen and (max-width: 992px) {
    .wrapper {
        display: none;
    }
}

.beta {
    text-align: center;
    font-size: 16px;
    color: #edeff4;
    font-weight: 400;
    margin-bottom: 20px;
}

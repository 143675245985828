.tipsBtn {
    padding: 10px 26px;
    background: #333333;
    border-radius: 100px;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #fff;
    margin-bottom: 10px;
    cursor: pointer;
}
.active {
    background: #2261f6;
}

.wrapper {
    background: #282b31;
    border: 0.704225px solid #6b6b6b;
    border-radius: 6px;
    aspect-ratio: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    &:hover {
        border: 1px solid var(--primary-color);
    }
}
.icon {
    margin-bottom: 12px;
}
.label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #fff;
}
.network {
    text-transform: capitalize;
}
.active {
    border: 2px solid #2261f6;
}

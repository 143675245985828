.wrapper {
    display: flex;
    justify-content: center;
    color: #fff;
}
.container {
    width: 296px;
}
.title {
    font-weight: 400;
    font-size: 18px;
    line-height: 145%;
    margin-top: 16px;
    margin-bottom: 3px;
    text-align: center;
    word-wrap: break-word;
}
.qr {
    margin-bottom: 17px;
    canvas {
        border: 1px solid #55afcb;
        border-radius: 16px;
    }
}
.btn {
    margin-bottom: 25px;
    a {
        display: block;
    }
}
.table {
    margin-bottom: 24px;
}
.tableRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    &:last-child {
        margin-bottom: 0;
    }
}
.price {
    margin-bottom: 39px;
    text-align: center;
}
.priceCrypto {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(217, 217, 217, 0.2);
    margin-bottom: 15px;
}
.priceFiat {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #b2b8cc;
    display: flex;
    justify-content: center;
}
.flex {
    display: flex;
    justify-content: center;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 29px;
    width: 460px;
    padding-top: 36px;
}
.logOut {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: #282b31;
    border-radius: 50%;
    cursor: pointer;
}
@media screen and (max-width: 520px) {
    .header {
        width: 100%;
    }
}

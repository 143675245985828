.loader {
	display: flex;
	animation: 8s linear infinite rotate;
}

.square {
	background: #f7f8fa;
	width: 16px;
	height: 16px;

	@for $i from 1 through 4 {
		&:nth-child(#{$i}) {
			animation: 1s linear infinite square#{$i};
		}
	}
}

@keyframes square1 {
	0%,
	20% {
		opacity: 0.2;
	}

	30% {
		opacity: 0.2;
	}

	40% {
		opacity: 0.4;
	}

	50%,
	70% {
		opacity: 1;
	}

	80% {
		opacity: 0.8;
	}

	90% {
		opacity: 0.2;
	}

	100% {
		opacity: 0.2;
	}
}

@keyframes square2 {
	0%,
	20% {
		opacity: 0.4;
	}

	30% {
		opacity: 0.4;
	}

	40% {
		opacity: 1;
	}

	50%,
	70% {
		opacity: 0.8;
	}

	80% {
		opacity: 1;
	}

	90% {
		opacity: 0.8;
	}

	100% {
		opacity: 0.4;
	}
}

@keyframes square3 {
	0%,
	20% {
		opacity: 0.8;
	}

	30% {
		opacity: 1;
	}

	40% {
		opacity: 0.8;
	}

	50%,
	70% {
		opacity: 0.4;
	}

	80% {
		opacity: 0.4;
	}

	90% {
		opacity: 1;
	}

	100% {
		opacity: 0.8;
	}
}

@keyframes square4 {
	0%,
	20% {
		opacity: 1;
	}

	30% {
		opacity: 0.8;
	}

	40% {
		opacity: 0.2;
	}

	50%,
	70% {
		opacity: 0.2;
	}

	80% {
		opacity: 0.2;
	}

	90% {
		opacity: 0.4;
	}

	100% {
		opacity: 1;
	}
}

body {
    margin: 0;
    font-family: 'Inter', 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
    line-height: 1.5;
    background-color: var(--background-color);
}

:root {
    --background-color: #12161e;
    --font-base-color: #fff;
    --primary-color: #2261f6;
    --container-side-padding: 15px;

    /* z-index */
    --z-index-mobile-menu: 2;
    --z-index-modals: 10;
    --z-index-select-com: 3;

    /* containers width */
    --container-width: 620px;

    /* animation */
    --animation-duration: 0.2s;
}

/* Указываем box sizing */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Убираем внутренние отступы */
ul[class],
ol[class] {
    padding: 0;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
}

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul[class],
ol[class] {
    list-style: none;
}

/* Элементы a, у которых нет класса, сбрасываем до дефолтных стилей */
a:not([class]) {
    text-decoration-skip-ink: auto;
}

/* Упрощаем работу с изображениями */
img {
    max-width: 100%;
    display: block;
}

/* Указываем понятную периодичность в потоке данных у article*/
article > * + * {
    margin-top: 1em;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
    font: inherit;
}

/* Удаляем все анимации и переходы для людей, которые предпочитают их не использовать */
/* @media (prefers-reduced-motion: reduce) {
    * {
        animation-duration: 0.01ms !important;
        animation-iteration-count: 1 !important;
        transition-duration: 0.01ms !important;
        scroll-behavior: auto !important;
    }
} */

/* fonts

Light 300 

Regular 400 

Medium 500 

SemiBold 600 

Bold 700  
*/

button {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
}

a {
    text-decoration: none;
    color: inherit;
}

button,
a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
    background-color: var(--background-color);
}
.react-datepicker {
    background-color: transparent;
    color: #fff;
    border: none;
}
.react-datepicker__header {
    background-color: transparent;
    border: none;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: #fff;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    color: #fff;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}
.react-datepicker__day--outside-month {
    color: #aaaaaa;
}
.react-datepicker__day--keyboard-selected {
    background: #2261f6;
}
.react-datepicker__day--selected {
    background: #2261f6;
}
.react-datepicker__day:hover {
    background: #2261f6;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    width: 39px;
    height: 37px;
    line-height: 37px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
}
.react-datepicker__day-name {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
}
@media screen and (max-width: 350px) {
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
        width: 34px;
        height: 32px;
        line-height: 32px;
    }
}
.react-datepicker__navigation-icon::before {
    border-color: #fff;
}
.react-datepicker__navigation-icon--next::after {
    border-color: #fff;
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: '';
    display: block;
    height: 9px;
    position: absolute;
    top: 6px;
    width: 9px;
    transform: rotate(45deg);
    left: -14px;
}
.react-datepicker__navigation-icon--previous::after {
    border-color: #fff;
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: '';
    display: block;
    height: 9px;
    position: absolute;
    top: 6px;
    width: 9px;
    transform: rotate(225deg);
    right: -14px;
}
.react-datepicker__navigation:hover *::before {
    border-color: #fff;
}
.react-datepicker__day--disabled {
    opacity: 0.5;
}
.hidden {
    overflow: hidden !important;
}
.transparent p {
    color: transparent;
}

.wrapper {
    display: flex;
    flex-flow: column nowrap;
    flex-grow: 1;
    align-items: center;
    max-width: 460px;
}

.container {
    width: 100%;
    padding-bottom: 100px;
}

@media screen and (max-width: 576px) {
    .wrapper {
        padding: 8px var(--container-side-padding) 20px
            var(--container-side-padding);
        padding-top: 16px;
        width: 100%;
    }
}

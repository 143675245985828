$height: 48px;

.wrapper {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    gap: 6px;
    font-family: 'Rubik';
    font-size: 16px;
    line-height: 16px;
    color: #ffffff;

    label {
        font-family: 'Rubik';
        font-weight: 400;
        font-size: 12px;
        line-height: 1.5;
        color: #8a929a;
    }
}

.block {
    height: $height;
    width: 100%;
    padding-left: 12px;
    padding-right: 8px;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: center;
    background: #333333;
    border: 1px solid #55afcb;
    border-radius: 8px;

    &.open {
        .arrow {
            transform: rotate(180deg);
        }
    }
}

.list {
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% + 12px);
    z-index: var(--z-index-select-com);
    background: #333333;
    border: 1px solid #55afcb;
    border-radius: 8px;
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;

    &Item {
        height: $height;
        display: flex;
        align-items: center;
        padding-left: 12px;
        cursor: pointer;
        border: none !important;
        border-radius: none !important;
        background-color: transparent !important;
        &:hover {
            color: var(--primary-color);
        }
    }

    &ItemActive {
        background: var(--primary-color);

        &:hover {
            color: #fff;
        }
    }
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

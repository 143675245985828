.wrapper {
    margin-top: 15%;
    width: 460px;
}
.content {
    text-align: center;
}
.logo {
    display: flex;
    justify-content: center;
    margin-bottom: 44px;
}
.title {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;
    color: #ffffff;
    margin-bottom: 20px;
}
.subTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #80ff77;
    margin-bottom: 20px;
}
.inputWrapperTitile {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    font-size: 24px;
    line-height: 120%;
    color: #ffffff;
    margin-bottom: 20px;
}
.subTitlNot {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #ff0000;
}
.flex {
    height: 100vh;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 24px;
    text-align: center;
}

@media screen and (max-width: 520px) {
    .wrapper {
        padding-right: 10px;
        padding-left: 10px;
        width: 100%;
    }
}

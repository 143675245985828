.button {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Rubik';
    padding: 0 24px;
    width: 80%;
}

.disabled {
    background: rgba(229, 229, 229, 0.2);
    color: #8e8e8e;
    pointer-events: none;
}

.primary {
    background: var(--primary-color);
}

.secondary {
    background: #0a9500;
}

.common {
    background: #333333;
}

.danger {
    background: #ff0000;
}

.fullWidth.button {
    width: 100%;
}

// sizes
.large.button {
    height: 48px;
    font-size: 16px;
    border-radius: 8px;
}

.medium.button {
    height: 40px;
    font-size: 16px;
    font-weight: 400;
    text-transform: none;
    border-radius: 8px;
}

.small.button {
    height: 32px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
}

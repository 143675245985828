.title {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 100%;
    color: #ffffff;
    margin-bottom: 20px;
}
.row {
    display: flex;
    align-items: end;
    margin-bottom: 20px;
    .col {
        &:first-child {
            width: 60%;
            margin-right: 12px;
        }
        &:last-child {
            width: 40%;
        }
    }
}
.subtitile {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 14px;
    color: #ffffff;
    margin-bottom: 8px;
}
.rowCryptocurrency {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 16px;
}
.cryptocurrencyBlock {
    margin-bottom: 21px;
}
.price {
    margin-bottom: 40px;
}
.priceTitle {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 14px;
    color: #fff;
    text-align: right;
}
.inputWrap {
    border-bottom: 1px solid rgba(217, 217, 217, 0.2);
    display: flex;
    width: 100%;
    max-width: 460px;
}
.inputTicker {
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    color: #fff;
}
.input {
    background-color: transparent;
    border: none;
    padding-bottom: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 40px;
    color: #fff;
    outline: none;
    width: 100%;
    padding-right: 6px;
    text-align: right;
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    &::placeholder {
        color: #fff;
    }
}
.tipsBtns {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    flex-wrap: wrap;
}
.rowField {
    margin-bottom: 17px;
}
.btn {
    padding: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #fff;
    text-align: center;
    background: #336bff;
    border-radius: 8px;
    cursor: pointer;
    min-width: 296px;
}
.centered {
    margin-top: 50px;
    display: flex;
    justify-content: center;
}
.flex {
    display: flex;
    justify-content: center;
}
.flexGrow {
    flex-grow: 1;
}
.inputTicker {
    flex-shrink: 1;
}
@media screen and (max-width: 460px) {
    .rowCryptocurrency {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
    }
}

.wrapper {
    display: flex;
    flex-flow: column nowrap;
    gap: 6px;
    width: 100%;
    position: relative;
    label {
        font-family: 'Rubik';
        font-weight: 400;
        font-size: 12px;
        color: #8a929a;
    }
}

.input {
    width: 100%;
    height: 48px;
    background: #333333;
    border-radius: 8px;
    padding-left: 16px;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    border: 1px solid #55afcb;
    outline: none;

    &::placeholder {
        color: #fff;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

@media screen and (max-width: 992px) {
    .input {
        font-size: 14px;
    }
}
.clearBtn {
    background: #282b31;
    border-radius: 8px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 13px;
    top: calc(50% - 5px);
    cursor: pointer;
}
